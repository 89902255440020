import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import Topbar from '../common/Topbar';
import Footer from '../common/Footer';
import Sidebar from '../common/Sidebar';
import SearchSort from '../common/SearchSort';
import NewReportModal from './NewReportModal';
import LogoutModal from '../common/LogoutModal';
import { getAllReports } from '../../actions/reportActions';

const columns = [
  {
    name: 'Report Name',
    selector: 'name',
    width: '25%',
    style: {
      fontSize: '0.8rem',
      fontWeight: 'bold'
    }
  },
  {
    name: 'Created By',
    selector: 'createdBy',
    width: '15%',
    center: true
  },
  {
    name: 'Plant',
    cell: (row) => (row.type === 'Consumption' ? row.plant : '-'),
    width: '10%',
    center: true
  },
  {
    name: 'Report Type',
    width: '10%',
    selector: 'type',
    center: true,
    sortable: true
  },
  {
    name: 'Report Duration',
    width: '15%',
    center: true,
    cell: (row) =>
      /**
       * Allocation time is stored in UTC format in database so we have to store
       * Dates in UTC format start and end date in reports
       * So to display reports creation date in IST we have to add 1 day
       */
      `${moment(row.startDate).add(1, 'day').format('DD-MM-YY')} to ${moment(
        row.endDate
      ).format('DD-MM-YY')}`
  },
  {
    name: 'Generated On',
    cell: (row) => moment(row.createdAt).format('DD-MM-YYYY - hh:mm A'),
    width: '15%',
    center: true
  },
  {
    name: 'Download',
    center: true,
    width: '10%',
    cell: (row) => (
      <button
        className="btn btn-info btn-sm shadow-sm ml-2"
        onClick={() => window.open(row.downloadLink, '_blank')}
      >
        Download
      </button>
    )
  }
];

function Reports({ getAllReports, reports, count, loading }) {
  useEffect(() => {
    getAllReports(1);
    // eslint-disable-next-line
  }, []);

  const [filter, setFilter] = useState({
    phrase: '',
    plant: '',
    createdBy: '',
    createdAt: '2025'
  });

  return (
    <div className="page-top">
      <div id="wrapper">
        <Sidebar />

        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar
              loading={loading}
              title="Reports"
              button={{
                trigger: 'createNewReport',
                icon: 'report',
                title: 'Create New Report'
              }}
            />

            <div className="container-fluid">
              <div className="card card-body shadow-sm">
                <SearchSort
                  search={(phrase) => {
                    setFilter({ ...filter, phrase });
                    getAllReports(1, { ...filter, phrase });
                  }}
                  setFilter={(res) => {
                    setFilter({ ...filter, ...res });
                    getAllReports(1, { ...filter, ...res });
                  }}
                  clearFilter={() => {
                    setFilter({ createdAt: '2025' });
                    getAllReports(1);
                  }}
                  filter={filter}
                  filters={{ plants: true }}
                  role="inventory,admin"
                />

                <DataTable
                  noHeader
                  customStyles={{
                    headCells: {
                      style: {
                        fontSize: '1em',
                        fontWeight: 'bold'
                      }
                    }
                  }}
                  striped
                  columns={columns}
                  highlightOnHover
                  pointerOnHover
                  data={reports}
                  pagination
                  paginationTotalRows={count}
                  paginationServer
                  paginationServerOptions={{
                    persistSelectedOnPageChange: true
                  }}
                  paginationComponentOptions={{ noRowsPerPage: true }}
                  onChangePage={async (pageNumber) =>
                    getAllReports(pageNumber, filter)
                  }
                  paginationPerPage={15}
                />
              </div>
            </div>

            <NewReportModal />
          </div>

          <Footer />
        </div>
      </div>
      <LogoutModal />
    </div>
  );
}

const mapStateToProps = (state) => ({
  reports: state.reports.reports,
  loading: state.reports.loading,
  count: state.reports.count
});

export default connect(mapStateToProps, { getAllReports })(Reports);
